import * as React from 'react';

const IconColorFB = props => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.333 18.056A.667.667 0 0 0 18 17.39V2.723a.667.667 0 0 0-.667-.667H2.667A.667.667 0 0 0 2 2.723V17.39c0 .368.298.666.667.666h14.666Z"
      fill="#2074BB"
    />
    <path
      d="M10.506 18.056v-6.333H8.333V9.389h2.173V7.665c0-2.067 1.262-3.192 3.106-3.192.883 0 1.642.065 1.863.095v2.16h-1.278c-1.003 0-1.197.477-1.197 1.176v1.485h2.333L15 11.723h-2v6.333h-2.494Z"
      fill="#fff"
    />
  </svg>
);

export default IconColorFB;
