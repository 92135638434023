import * as React from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';

const MasonryLayoutWrapper = styled.div`
  .masonry-grid {
    display: flex;
  }
  .masonry-grid-column {
    :not(:first-child) {
      margin-left: ${props => props.columnGap || 24}px;
    }
    > div:not(:last-child) {
      margin-bottom: ${props => props.rowGap || 24}px;
    }
  }
`;
export const MasonryLayout = ({ columnGap = 24, rowGap = 24, ...rest }) => {
  return (
    <MasonryLayoutWrapper columnGap={columnGap} rowGap={rowGap}>
      <Masonry
        className="masonry-grid"
        columnClassName="masonry-grid-column"
        {...rest}
      />
    </MasonryLayoutWrapper>
  );
};
